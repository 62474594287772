import { convertNullIfEmpty as convNull } from "@/utils/null-check";
import { getFullAddress } from "@/utils/string";

export class FloorPlanDesignModel {
    static postPayload(e) {
        return {
            floorPlanId: e.floorPlanId || "",
            name: e.name || "",
            panoramaLink: getFullAddress(e.panoramaLink) || "",
            mainPhoto: e.mainPhoto || [],
            gallery: e.gallery || [],
            estimatePrice: convNull(e.estimatePrice.toString()) || "0.00",
            description: e.description || ""
        };
    }

    static putPayload(e) {
        return {
            floorPlanId: e.floorPlanId || "",
            name: e.name || "",
            panoramaLink: getFullAddress(e.panoramaLink) || "",
            mainPhoto: e.mainPhoto || [],
            gallery: e.gallery || [],
            estimatePrice: convNull(e.estimatePrice.toString()) || "0.00",
            description: e.description || ""
        };
    }

    static getToEdit(e) {
        return {
            id: e.id,
            floorPlanId: e.floorPlan.id || "",
            name: e.name || "",
            panoramaLink: e.panoramaLink || "",
            mainPhoto: e.mainPhoto || [],
            gallery: e.gallery || [],
            estimatePrice: convNull(e.estimatePrice) || "",
            description: e.description || ""
        };
    }
}
