import { convertNullIfEmpty } from "@/utils/null-check";

export class ProfileModel {
    static putProfilePayload(e) {
        return {
            name: e.name || "",
            avatar: e.avatar || [],
            email: e.email || "",
            introduction: e.introduction || "",

            mobileISOCode: convertNullIfEmpty(e.phoneNo.mobileISOCode) || "",
            mobileCountryCode:
                convertNullIfEmpty(e.phoneNo.mobileCountryCode) || "",
            mobileNumber: convertNullIfEmpty(e.phoneNo.mobileNumber) || ""
        };
    }
    static getToEdit(e) {
        return {
            email: e.user?.email,
            bank: e.bank,

            name: convertNullIfEmpty(e.name) || "",
            avatar: e.avatar || [],
            introduction: e.introduction || "",

            mobileISOCode: e.user.mobileISOCode || "",
            mobileCountryCode: e.user.mobileCountryCode || "",
            mobileNumber: e.user.mobileNumber || ""
        };
    }
}
