import axios from "axios";
import { designerAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getDesigns({ queries = {}, page = 1, perPage = 30 }) {
    let include = "floorPlan";
    try {
        const response = await axios.get(`${designerAPI}/floor-plan-designs`, {
            params: { ...queries, page: page, limit: perPage, include: include }
        });

        return response.data;
    } catch (error) {
        reportError(error, "[Designer CP] Get Designs");
        throw error;
    }
}

async function getDesign(id) {
    let include = "floorPlan";

    try {
        const response = await axios.get(
            `${designerAPI}/floor-plan-designs/${id}?include=${include}`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Designer CP] Get Design (id: ${id})`);
        throw error;
    }
}

async function createDesign(payload) {
    try {
        const response = await axios.post(
            `${designerAPI}/floor-plan-designs`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Designer CP] Create Design`);
        throw error;
    }
}

async function updateDesign(id, payload) {
    try {
        const response = await axios.put(
            `${designerAPI}/floor-plan-designs/${id}`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Designer CP] Update Design (id: ${id})`);
        throw error;
    }
}

async function deleteDesign(id) {
    try {
        const response = await axios.delete(
            `${designerAPI}/floor-plan-designs/${id}`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Designer CP] Delete Design (id: ${id})`);
        throw error;
    }
}

export default {
    getDesigns,
    getDesign,
    createDesign,
    updateDesign,
    deleteDesign
};
