import axios from "axios";
import { designerAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getProjects({ queries = {}, page = 1, perPage = 30 }) {
    let include = "agency,agencyBranch";
    try {
        const response = await axios.get(`${designerAPI}/projects`, {
            params: { ...queries, page: page, limit: perPage, include: include }
        });

        return response.data;
    } catch (error) {
        reportError(error, "[Designer CP] Get Projects");
        throw error;
    }
}

async function getProject(id) {
    let include = "agency,agencyBranch";
    try {
        const response = await axios.get(`${designerAPI}/projects/${id}`, {
            params: { include: include }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Designer CP] Get Project (id: ${id})`);
        throw error;
    }
}

async function getFloorPlans(
    projectId,
    { queries = {}, page = 1, perPage = 30 }
) {
    let include = "project";
    try {
        const response = await axios.get(`${designerAPI}/floor-plans`, {
            params: {
                ...queries,
                "project:id": projectId,
                page: page,
                limit: perPage,
                include: include
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, "[Designer CP] Get Floor Plans");
        throw error;
    }
}

async function getFloorPlan(id) {
    let include = "project";
    try {
        const response = await axios.get(`${designerAPI}/floor-plans/${id}`, {
            params: { include: include }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Designer CP] Get Floor Plan (id: ${id})`);
        throw error;
    }
}

export default {
    getProjects,
    getProject,
    getFloorPlans,
    getFloorPlan
};
