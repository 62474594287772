import axios from "axios";
import { API, designerAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getProfile() {
    try {
        const response = await axios.get(`${designerAPI}/account`);

        return response.data;
    } catch (error) {
        reportError(error, `[Designer CP] Get Profile`);
        throw error;
    }
}

async function updateProfile(payload) {
    try {
        const response = await axios.put(`${designerAPI}/account`, payload);

        return response.data;
    } catch (error) {
        reportError(error, `[Designer CP] Update Profile`);
        throw error;
    }
}

async function updatePassword(payload) {
    try {
        const response = await axios.put(
            `${designerAPI}/account/password`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Designer CP] Update Password`);
        throw error;
    }
}

async function forgetPassword(email) {
    try {
        const response = await axios.post(`${API}/reset-password`, {
            type: "designer",
            email: email
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Designer CP] Forget Password`);
        throw error;
    }
}

export default {
    getProfile,
    updateProfile,
    updatePassword,
    forgetPassword
};
