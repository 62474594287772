import axios from "axios";
import { commonAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getDevelopers(payload = { page: 1, perPage: 50 }) {
    try {
        let include = "";

        let response = await axios.get(`${commonAPI}/developers`, {
            params: {
                ...payload,
                include: include,
                limit: payload.perPage,
                page: payload.page
            }
        });
        return response.data;
    } catch (error) {
        reportError(error, `[Designer CP] Get Developers`);
        throw error;
    }
}

export default {
    getDevelopers
};
